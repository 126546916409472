import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tableFilters: {},
  columnFilters: [],
  selectedAllLocations:false,
  selectedLocationIds:[],
  unselectedLocationIds:[]
};

const locationUISlice = createSlice({
  name: "locationUI",
  initialState,
  reducers: {
    "account/logoutUser": (state) => initialState,
    storeLocationFilters(state, action) {
      state.tableFilters = action.payload;
    },
    storeLocationColumnFilters(state, action) {
      state.columnFilters = action.payload;
    },
    setSelectedAllLocations(state,action){
      state.selectedAllLocations=action.payload
    },
    setSelectedLocationIds(state,action){
      state.selectedLocationIds=action.payload
    },
    setUnselectedLocationIds(state,action){
      state.unselectedLocationIds=action.payload
    }
  },
  extraReducers: {},
});

export const getTableFilters = (state) => state.locationUI.tableFilters;
export const getColumnFilters = (state) => state.locationUI.columnFilters;
export const getSelectedAllLocations = (state) => state.locationUI.selectedAllLocations;
export const getSelectedLocationIds = (state) => state.locationUI.selectedLocationIds;
export const getUnselectedLocationIds = (state) => state.locationUI.unselectedLocationIds;

export const { storeLocationFilters, storeLocationColumnFilters,setSelectedAllLocations,setSelectedLocationIds,setUnselectedLocationIds } = locationUISlice.actions;

export default locationUISlice;
