import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tableFilters: {},
  columnFilters: {},
  selectedAllGoals:false,
  selectedGoalsIds:[],
  unselectedGoalsIds:[]
};

const goalsUISlice = createSlice({
  name: "goalsUI",
  initialState,
  reducers: {
    "account/logoutUser": (state) => initialState,
    storeGoalFilters(state, action) {
      state.tableFilters = action.payload;
    },
    clearGoalFilters(state, action) {
      state.tableFilters = {};
    },
    storeGoalColumnFilters(state, action) {
      state.columnFilters = action.payload;
    },
    setSelectedAllGoals(state, action) {
      state.selectedAllGoals = action.payload;
    },
    setSelectedGoalsIds(state, action) {
      state.selectedGoalsIds = action.payload;
    },
    setUnselectedGoalsIds(state, action) {
      state.unselectedGoalsIds = action.payload;
    },  
  },
  extraReducers: {},
});

export const getTableFilters = (state) => state.goalsUI.tableFilters;
export const getColumnFilters = (state) => state.goalsUI.columnFilters;
export const getSelectedAllGoals = (state) => state.goalsUI.selectedAllGoals;
export const getSelectedGoalsIds = (state) => state.goalsUI.selectedGoalsIds;
export const getUnselectedGoalsIds = (state) => state.goalsUI.unselectedGoalsIds;

export const {
  storeGoalFilters,
  clearGoalFilters,
  storeGoalColumnFilters,
  setSelectedAllGoals,
  setSelectedGoalsIds,
  setUnselectedGoalsIds
} = goalsUISlice.actions;

export default goalsUISlice;
