import { createAsyncThunk } from "@reduxjs/toolkit";
import { checkStatus, AuthError, composeAuth } from "../../helpers/utils";
import { logoutUser } from "../actions/account";
import querystring from "query-string";

export const getFormByModule = createAsyncThunk(
    "forms/getFormByModule",
    async (payload, thunkApi) => {
        try {
            const url =
                process.env.REACT_APP_SERVER + `/api/forms/module/${payload?.module}/institutionId/${payload?.institutionId}`;
            const Authorization = composeAuth(payload?.jwt);
            const res = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-type": "application/json",
                    Authorization,
                },
            });

            const response = await res.json();

            const validateData = checkStatus(response);
            return validateData;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const getFormSchemaByKey = createAsyncThunk(
    "forms/getFormSchemaByKey",
    async (payload, thunkApi) => {
        try {
            const Authorization = composeAuth(payload?.jwt);
            const url =
                process.env.REACT_APP_SERVER + (payload?.jwt ? `/api/forms/key/${payload?.key}` : `/api/forms/public/key/${payload?.key}`)
            const res = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-type": "application/json",
                    Authorization,
                },
            });

            const response = await res.json();

            const validateData = checkStatus(response);
            return validateData;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const getFormSchemaByKeyAndModule = createAsyncThunk(
    "forms/getFormSchemaByKeyAndModule",
    async (payload, thunkApi) => {
        try {
            const Authorization = composeAuth(payload?.jwt);
            const url =
                process.env.REACT_APP_SERVER + `/api/forms/module/key/${payload?.key}`
            const res = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-type": "application/json",
                    Authorization,
                },
            });

            const response = await res.json();

            const validateData = checkStatus(response);
            return validateData;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const getFormsForCalender = createAsyncThunk(
    "forms/getFormsForCalender",
    async (payload, thunkApi) => {
        try {
            const Authorization = composeAuth(payload?.jwt);
            const url =
                process.env.REACT_APP_SERVER + `/api/forms/calendar/institution/${payload?.id}`
            const res = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-type": "application/json",
                    Authorization,
                },
            });

            const response = await res.json();

            const validateData = checkStatus(response);
            return validateData;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const getFormsByTagId = createAsyncThunk(
    "forms/getFormsByTagId",
    async (payload, thunkApi) => {
        try {
            const Authorization = composeAuth(payload?.jwt);
            const url =
                process.env.REACT_APP_SERVER + `/api/forms/tag/${payload?.tagId}`
            const res = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-type": "application/json",
                    Authorization,
                },
            });

            const response = await res.json();

            const validateData = checkStatus(response);
            return validateData;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const getFormsBymultipleTagId = createAsyncThunk(
    "forms/getFormsBymultipleTagId",
    async (payload, thunkApi) => {
        try {
            const Authorization = composeAuth(payload?.jwt);
            const url =
                process.env.REACT_APP_SERVER + `/api/forms/tags?${querystring.stringify({
                    tags: payload?.tag_ids,
                })}`
            const res = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-type": "application/json",
                    Authorization,
                },
            });

            const response = await res.json();

            const validateData = checkStatus(response);
            return validateData;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const shareForm = createAsyncThunk(
    "forms/shareForm",
    async (payload, thunkApi) => {
        try {
            const Authorization = composeAuth(payload?.jwt);
            const url = process.env.REACT_APP_SERVER + `/api/goals/share/form`;
            const res = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                    Authorization,
                },
                body: JSON.stringify(payload?.requestBody),
            });

            const response = await res.json();
            const validateData = checkStatus(response);
            return validateData;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const sendCommunications = createAsyncThunk(
    "forms/shareForm",
    async (payload, thunkApi) => {
        try {
            const Authorization = composeAuth(payload?.jwt);
            const url = process.env.REACT_APP_SERVER + `/api/goals/send-communication`;
            const res = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                    Authorization,
                },
                body: JSON.stringify(payload?.requestBody),
            });

            const response = await res.json();
            const validateData = checkStatus(response);
            return validateData;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);