import { createSlice } from "@reduxjs/toolkit";
import { getFormSchemaByKey, getFormsByTagId, getFormsForCalender, getFormSchemaByKeyAndModule, getFormByModule, shareForm, getFormsBymultipleTagId } from "../actions/form";
import { logoutUser } from "../actions/account";

const initialState = {
    error: false,
    isLoadingGetFormSchemaByKey: false,
    formSchemaForKey: [],
    formDetails: {},
    isLoadingGetFormsByTagId: false,
    isLoadingGetCalenderForms: false,
    formsForCalender: [],
    formsForTag: [],
    isLoadingGetFormByKeyAndModule: false,
    formSchemaForKeyAndModule: [],
    isLoadingGetFormByModule: false,
    formsForModule: [],
    isLoadingShareForm: false,
    formsForMultiTag: [],
    isLoadingGetFormsByMultiTagId: false
};

const formSlice = createSlice({
    name: "forms",
    initialState,
    reducers: {
        "account/logoutUser": (state) => initialState,
    },
    extraReducers: {
        [getFormSchemaByKey.pending]: (state, action) => {
            state.isLoadingGetFormSchemaByKey = true;
            state.error = false;
        },
        [getFormSchemaByKey.fulfilled]: (state, action) => {
            state.isLoadingGetFormSchemaByKey = false;
            state.formSchemaForKey = action?.payload?.data?.goals_schema;
            state.formDetails = action?.payload?.data?.form;
            state.error = false;
        },
        [getFormSchemaByKey.rejected]: (state, action) => {
            state.isLoadingGetFormSchemaByKey = false;
            state.error = true;
        },//getFormByModule
        [getFormByModule.pending]: (state, action) => {
            state.isLoadingGetFormByModule = true;
            state.error = false;
        },
        [getFormByModule.fulfilled]: (state, action) => {
            state.isLoadingGetFormByModule = false;
            state.formsForModule = action?.payload?.data?.form;
            state.error = false;
        },
        [getFormByModule.rejected]: (state, action) => {
            state.isLoadingGetFormByModule = false;
            state.error = true;
        },//getFormSchemaByKeyAndModule
        [getFormSchemaByKeyAndModule.pending]: (state, action) => {
            state.isLoadingGetFormByKeyAndModule = true;
            state.error = false;
        },
        [getFormSchemaByKeyAndModule.fulfilled]: (state, action) => {
            state.isLoadingGetFormByKeyAndModule = false;
            state.formSchemaForKeyAndModule = action?.payload?.data?.schema;
            state.formDetails = action?.payload?.data?.form;
            state.error = false;
        },
        [getFormSchemaByKeyAndModule.rejected]: (state, action) => {
            state.isLoadingGetFormByKeyAndModule = false;
            state.error = true;
        },//shareForm
        [shareForm.pending]: (state, action) => {
            state.isLoadingShareForm = true;
            state.error = false;
        },
        [shareForm.fulfilled]: (state, action) => {
            state.isLoadingShareForm = false;
            state.error = false;
        },
        [shareForm.rejected]: (state, action) => {
            state.isLoadingShareForm = false;
            state.error = true;
        },
        [getFormsForCalender.pending]: (state, action) => {
            state.isLoadingGetCalenderForms = true;
            state.error = false;
        },
        [getFormsForCalender.fulfilled]: (state, action) => {
            state.isLoadingGetCalenderForms = false;
            state.formsForCalender = action?.payload?.data?.forms
            state.error = false;
        },
        [getFormsForCalender.rejected]: (state, action) => {
            state.isLoadingGetCalenderForms = false;
            state.error = true;
        },
        [getFormsByTagId.pending]: (state, action) => {
            state.isLoadingGetFormsByTagId = true;
            state.error = false;
        },
        [getFormsByTagId.fulfilled]: (state, action) => {
            state.isLoadingGetFormsByTagId = false;
            state.formsForTag = action?.payload?.data?.form;
            state.error = false;
        },
        [getFormsByTagId.rejected]: (state, action) => {
            state.isLoadingGetFormsByTagId = false;
            state.error = true;
        },
        [getFormsBymultipleTagId.pending]: (state, action) => {
            state.isLoadingGetFormsByMultiTagId = true;
            state.error = false;
        },
        [getFormsBymultipleTagId.fulfilled]: (state, action) => {
            state.isLoadingGetFormsByMultiTagId = false;
            state.formsForMultiTag = action?.payload?.data?.forms;
            state.error = false;
        },
        [getFormsBymultipleTagId.rejected]: (state, action) => {
            state.isLoadingGetFormsByMultiTagId = false;
            state.error = true;
        },
        [logoutUser.fulfilled]: (state) => {
            return initialState;
        },
        [logoutUser.rejected]: (state) => {
            return initialState;
        },
    },
});

export const getError = (state) => state.forms.error;
export const getIsLoadingGetFormByKey = (state) => state.forms.isLoadingGetFormSchemaByKey;
export const getFormSchemaForKey = (state) => state.forms.formSchemaForKey;
export const getFormSchemaForKeyAndModule = (state) => state.forms.formSchemaForKeyAndModule;
export const getIsLoadingGetFormsByTagId = (state) => state.forms.isLoadingGetFormsByTagId;
export const getFormsForTag = (state) => state.forms.formsForTag;
export const getFormDetails = (state) => state.forms.formDetails;
export const getIsLoadingCalenderForms = (state) => state.forms.isLoadingGetCalenderForms;
export const getCalenderForms = (state) => state.forms.formsForCalender;
export const getIsLoadingGetFormByKeyAndModule = (state) => state.forms.isLoadingGetFormByKeyAndModule;
export const getIsLoadingFormByModule = (state) => state.forms.isLoadingGetFormByModule;
export const getFormsForModule = (state) => state.forms.formsForModule;
export const getIsLoadingShareForm = (state) => state.forms.isLoadingShareForm;
export const getFormsForMultiTag = (state) => state.forms.formsForMultiTag;
export const getIsLoadingGetFormsByMultiTagId = (state) => state.forms.isLoadingGetFormsByMultiTagId;


export default formSlice;
