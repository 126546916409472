import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tableFilters: {},
  columnFilters: [],
  selectedAllClients:false,
  selectedClientsIds:[],
  unselectedClientsIds:[]
};

const clientUISlice = createSlice({
  name: "clientUI",
  initialState,
  reducers: {
    "account/logoutUser": (state) => initialState,
    storeClientFilters(state, action) {
      state.tableFilters = action.payload;
    },
    storeClientColumnFilters(state, action) {
      state.columnFilters = action.payload;
    },
    setSelectedAllClients(state, action) {
      state.selectedAllClients = action.payload;
    },
    setSelectedClientsIds(state, action) {
      state.selectedClientsIds = action.payload;
    },
    setUnselectedClientsIds(state, action) {
      state.unselectedClientsIds = action.payload;
    },  
  },
  extraReducers: {},
});

export const getTableFilters = (state) => state.clientUI.tableFilters;
export const getColumnFilters = (state) => state.clientUI.columnFilters;
export const getSelectedAllClients = (state) => state.clientUI.selectedAllClients;
export const getSelectedClientsIds = (state) => state.clientUI.selectedClientsIds;
export const getUnselectedClientsIds = (state) => state.clientUI.unselectedClientsIds;

export const { storeClientFilters, storeClientColumnFilters, setSelectedAllClients, setSelectedClientsIds, setUnselectedClientsIds } =
  clientUISlice.actions;

export default clientUISlice;
